import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const signedIdentityJwt = searchParams.get('signedIdentity');
  const [decodedJwt, setDecodedJwt] = useState(null);
  const [error, setError] = useState('');

  const parseJwt = (token) => {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    try {
      setDecodedJwt(parseJwt(signedIdentityJwt));
    } catch (error) {
      setError(error);
      console.log(error);
    }
  }, [signedIdentityJwt]);

  return (
    <div className="App">
      <header className="App-header">
        {!!signedIdentityJwt ? (
          <div>
            <h1>Decoded JWT</h1>
            {error ? <code>Invalid JWT</code> : <code>{JSON.stringify(decodedJwt, null, 4)}</code>}
            <p>
              <a className="App-link" href={`https://jwt.io/#debugger-io?token=${signedIdentityJwt}`} target="_blank" rel="noreferrer">View in JWT.io</a>
            </p>
          </div>
        ) : (
          <>
            <h1>No <code>signedIdentity</code> parameter found</h1>
            <p>Example: {window.location.href}?signedIdentity=<em>&lt;JWT&gt;</em></p>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
